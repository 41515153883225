<div class="row">
  <div class="col-6">
    <nz-form-item>
      <nz-form-label nzFor="employee_name" class="text-left">Nama</nz-form-label>
      <nz-form-control nzErrorTip="Please input your name correctly!">
        <input
          nz-input
          id="employee_name"
          [formControl]="formAddPermitRequest.controls['employee_name']"
          placeholder="Input your name"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="division" class="text-left">Division</nz-form-label>
      <nz-form-control nzErrorTip="Please input division correctly!">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select a division"
          [formControl]="formAddPermitRequest.controls['division']"
        >
          <nz-option
            *ngFor="let option of listofDivision"
            [nzLabel]="option.name"
            [nzValue]="option.id"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label class="text-left">Tipe izin</nz-form-label>
      <nz-form-control nzErrorTip="Please input type correctly!">
        <nz-select nzPlaceHolder="Tipe Izin" nzShowSearch [(ngModel)]="permitType">
          <nz-option *ngFor="let item of listPermitType" [nzLabel]="item.type" [nzValue]="item.tag">
            {{ item.type }}
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- IZIN KELUAR KANTOR -->
    <div *ngIf="permitType == 'keluar_kantor'">
      <nz-form-item>
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Tanggal</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              id="permitDate"
              [nzDisabledDate]="disabledStartSickDate"
              [(ngModel)]="sickDateFrom"
              nzPlaceHolder="Select start date"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
              (ngModelChange)="onChangeLateTime($event)"
              [nzDisabledDate]="isDisabledDate"
            ></nz-date-picker>
            <div>-</div>
            <nz-date-picker
              id="permitDate"
              [nzDisabledDate]="disabledStartSickDate"
              [(ngModel)]="sickDateUntil"
              nzPlaceHolder="Select end date"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
              [nzDisabledDate]="isDisabledDate"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Tempat</nz-form-label>
        <nz-form-control nzErrorTip="Please input type correctly!">
          <nz-select nzPlaceHolder="Pilih tempat kantor" nzShowSearch [(ngModel)]="placementCode">
            <nz-option
              *ngFor="let item of listPlacement"
              [nzLabel]="item.name"
              [nzValue]="item.code"
            >
              {{ item.name }}
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="placementCode == 'other'">
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Tempat lainnya</nz-form-label>
        <nz-form-control nzErrorTip="Please input type correctly!">
          <input
            nz-input
            id="employee_name"
            [(ngModel)]="otherPlace"
            placeholder="Input other place"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- IZIN SAKIT -->
    <div *ngIf="permitType == 'sakit'">
      <nz-form-item>
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Tanggal</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              id="permitDate"
              [nzDisabledDate]="disabledStartSickDate"
              [(ngModel)]="sickDateFrom"
              nzPlaceHolder="Select start date"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
              (ngModelChange)="onChangeLateTime($event)"
              [nzDisabledDate]="isDisabledDate"
            ></nz-date-picker>
            <div>-</div>
            <nz-date-picker
              id="permitDate"
              [nzDisabledDate]="disabledStartSickDate"
              [(ngModel)]="sickDateUntil"
              nzPlaceHolder="Select end date"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
              [nzDisabledDate]="isDisabledDate"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- IZIN TELAT -->
    <div *ngIf="permitType == 'telat'">
      <nz-form-item>
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Jam masuk</nz-form-label>
        <nz-form-control nzErrorTip="Please pick time correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-time-picker
              id="permitTimeFrom"
              [formControl]="formAddPermitRequest.controls['permitTimeFrom']"
              nzFormat="HH:mm"
              [nzDisabledHours]="disabledHours"
              nzHideDisabledOptions
              (ngModelChange)="onChangeLateTime($event)"
            ></nz-time-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- IZIN KHUSUS -->
    <div *ngIf="permitType == 'khusus'">
      <nz-form-item>
        <nz-form-label class="text-left">Tanggal</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              id="permitDate"
              [nzDisabledDate]="disabledStartDate"
              [formControl]="formAddPermitRequest.controls['permitDate']"
              nzPlaceHolder="Select date"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
              [nzDisabledDate]="isDisabledDate"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label class="text-left">Waktu izin</nz-form-label>
        <nz-radio-group [(ngModel)]="permitShift" (ngModelChange)="onChangeRadio($event)">
          <label nz-radio nzValue="morning">Pagi</label>
          <label nz-radio nzValue="evening">Sore</label>
        </nz-radio-group>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="permitTimeFrom" class="text-left">Dari jam</nz-form-label>
        <nz-form-control nzErrorTip="Please pick time correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <div>
              {{ permitShift == 'morning' ? '08:30' : '13:00' }}
            </div>
          </div>
        </nz-form-control>
        <nz-form-label class="text-left">Sampai jam</nz-form-label>
        <nz-form-control nzErrorTip="Please pick time correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <div>
              {{ permitShift == 'morning' ? '13:00' : '17:30' }}
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="col-6" *ngIf="permitType">
    <nz-form-item>
      <nz-form-label nzFor="reason" class="text-left">Alasan</nz-form-label>
      <nz-form-control
        nzErrorTip="Please input reason correctly!"
        *ngIf="permitType == 'khusus' || permitType == 'sakit' || permitType == 'keluar_kantor'"
      >
        <textarea
          nz-input
          [formControl]="formAddPermitRequest.controls['reason']"
          rows="3"
          placeholder="Type here"
        ></textarea>
      </nz-form-control>
      <nz-form-control nzErrorTip="Please input reason correctly!" *ngIf="permitType == 'telat'">
        <nz-select
          nzPlaceHolder="Alasan"
          nzShowSearch
          [formControl]="formAddPermitRequest.controls['reason']"
        >
          <nz-option *ngFor="let item of listLateType" [nzLabel]="item" [nzValue]="item">
            {{ item }}
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="permitType && permitType != 'keluar_kantor'">
      <nz-form-label nzFor="attachment" class="text-left">Attachment</nz-form-label>
      <nz-form-control>
        <nz-upload
          nzListType="picture-card"
          [(nzFileList)]="fileList"
          [nzPreview]="handlePreview"
          [nzDownload]="handleDownload"
          [nzRemove]="handleRemove"
          [nzCustomRequest]="customUploadReq"
          [nzBeforeUpload]="handleBeforeUpload"
          [nzAccept]="'image/png,image/jpeg,image/jpg,application/pdf'"
          [nzDisabled]="fileList.length > 0"
          [nzShowButton]="!(fileList.length > 0)"
          (nzChange)="handleChange($event)"
        >
          <div>
            <i nz-icon nzType="plus"></i>
            <div style="margin-top: 8px">Upload</div>
          </div>
        </nz-upload>
        <nz-modal
          [nzVisible]="previewVisible"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="previewVisible = false"
        >
          <ng-template #modalContent>
            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
          </ng-template>
        </nz-modal>
      </nz-form-control>
    </nz-form-item>

    <div *ngIf="permitType == 'keluar_kantor'">
      <div class="col">
        <nz-select
          nzPlaceHolder="Pilih file SPT"
          nzShowSearch
          [(ngModel)]="selectedSPTFile"
          style="margin-left: 10px; width: 150px; height: 44px !important; padding-top: 4px !important; padding-bottom: 2px;"
        >
          <nz-option
            *ngFor="let item of SPTSelectValue"
            [nzLabel]="item.label"
            [nzValue]="item.value"
          >
            {{ item.label }}
          </nz-option>
        </nz-select>

        <button
          nz-button
          [nzSize]="'large'"
          nzType="default"
          style="margin-left: 10px;"
          (click)="downloadSPTFile()"
          [disabled]="!selectedSPTFile"
        >
          <span nz-icon nzType="download" nzTheme="outline"></span> Download SPT
        </button>
      </div>
    </div>
  </div>

  <div
    class="d-flex"
    style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
  >
    <button
      nz-button
      nzType="primary"
      (click)="submitForm()"
      [disabled]="!formAddPermitRequest.valid"
    >
      Submit
    </button>
    <button
      nz-button
      nzType="default"
      nzDanger
      style="background-color: rgb(231, 64, 64) !important; color: white;"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
