import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ListSubstituteType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-view-detail-leave',
  templateUrl: './view-detail-leave.component.html',
  styleUrls: ['./view-detail-leave.component.scss'],
})
export class ViewDetailLeaveComponent implements OnInit {
  @Input() leaveId: string
  @Input() filterStatus: string
  formDetailLeaveHr: FormGroup
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []
  leaveType = 'Annual Leave'

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modalRef: NzModalRef,
    private leaveManagementService: LeaveManagementService,
    private modal: NzModalService,
  ) {
    this.formDetailLeaveHr = this.fb.group({
      substitute_name: ['', [Validators.required]],
      startValue: ['', Validators.required],
      endValue: ['', Validators.required],
      reason: ['', [Validators.required]],
      employee_name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      notes_approval: [''],
    })
  }

  ngOnInit(): void {
    this.leaveManagementService.getDetailAnnualLeave(this.leaveId).subscribe(
      (r: any) => {
        const data = {
          substitute_name: r.substitute_name,
          startValue: r.date[0],
          endValue: r.date[1],
          reason: r.reason,
          employee_name: r.employee_name,
          type: r.type,
        }

        this.formDetailLeaveHr.patchValue(data)
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  closeModal(): void {
    this.modalRef.destroy()
  }

  rejectLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure reject this leave?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        //tambahin notes
        this.leaveManagementService
          .giveCancelRequestLeave(id, this.formDetailLeaveHr.value.notes_approval)
          .subscribe(
            r => {
              this.notification.success('Success', 'Successfully reject leave!')
              // this.fetchListApprovalHr()
              this.modalRef.destroy()
            },
            err => {
              this.notification.error('Error', err.error.message)
              // this.fetchListApprovalHr()
              this.modalRef.destroy()
            },
          )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  approveLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure approve this leave?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        //tambahin notes
        this.leaveManagementService
          .giveApprovalRequestLeaveHR(id, this.formDetailLeaveHr.value.notes_approval)
          .subscribe(
            r => {
              this.notification.success('Success', 'Successfully approve leave!')
              // this.fetchListApprovalHr()
              this.modalRef.destroy()
            },
            err => {
              this.notification.error('Error', err.error.message)
              // this.fetchListApprovalHr()
              this.modalRef.destroy()
            },
          )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }
}
