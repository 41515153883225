import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { PermitManagementService } from 'src/app/services/permit-management/permit-management.service'
import { NzImageService } from 'ng-zorro-antd/image'

@Component({
  selector: 'app-view-detail-permit',
  templateUrl: './view-detail-permit.component.html',
  styleUrls: ['./view-detail-permit.component.scss'],
})
export class ViewDetailPermitComponent implements OnInit {
  @Input() permitID: string
  @Input() filterStatus: string
  isLoading: boolean = false
  selectedValue = null

  employeeName: string = ''
  division: string = ''
  permitType: string = ''
  permitReason: string = ''
  placement: string = ''
  otherPlacement: string = ''
  attachment: string = ''
  status: string = ''
  notesApproval: string = ''
  permitDate: string[] = []
  timeofPermit: string[] = []

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modalRef: NzModalRef,
    private permitManagementService: PermitManagementService,
    private modal: NzModalService,
    private nzImageService: NzImageService
  ) {}

  ngOnInit(): void {
    this.permitManagementService.getDetailPermit(this.permitID).subscribe(
      (r: any) => {
        this.employeeName = r.employee_name
        this.permitType = r.type
        this.permitDate = r.date
        this.division = r.division
        this.placement = r.placement
        this.otherPlacement = r.other_place
        this.attachment = r.attachment
        this.status = r.status
        this.permitReason = r.reason
        if (this.status != 'WAITING') {
          this.notesApproval = r.notes_approval
        }
        this.isLoading = !this.isLoading
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  closeModal(): void {
    this.modalRef.destroy()
  }

  rejectPermit(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure reject this permit?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        //tambahin notes
        this.permitManagementService.giveCancelRequestPermit(id, this.notesApproval).subscribe(
          r => {
            this.notification.success('Success', 'Successfully reject permit!')
            // this.fetchListApprovalHr()
            this.modalRef.destroy()
          },
          err => {
            this.notification.error('Error', err.error.message)
            // this.fetchListApprovalHr()
            // this.modalRef.destroy()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  approvePermit(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure approve this permit?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        //tambahin notes
        this.permitManagementService.giveApproveRequestPermit(id, this.notesApproval != '' ? "APPROVED" : this.notesApproval).subscribe(
          r => {
            this.notification.success('Success', 'Successfully approve permit!')
            // this.fetchListApprovalHr()
            this.modalRef.destroy()
          },
          err => {
            this.notification.error('Error', err.error.message)
            // this.fetchListApprovalHr()
            // this.modalRef.destroy()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  openImageModal(attachment: any): void {
    this.nzImageService.preview(
      [{
        src: attachment,
        alt: 'attachment-preview'
      }],
      {
        nzZoom: 1,
        nzRotate: 0 
      }
    );
  }
}
