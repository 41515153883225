<div class="row">
  <div class="col-6">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Nama</h4>
      <h4>{{ employeeName }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Divisi</h4>
      <h4>{{ division }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Tipe</h4>
      <h4>{{ permitType }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Tanggal Izin</h4>
      <h4>{{ permitDate }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Waktu Izin</h4>
      <h4>{{ timeofPermit }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Placement</h4>
      <h4>{{ placement || otherPlacement }}</h4>
    </div>
  </div>
  
  <div class="col-6">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Alasan izin</h4>
      <h4>{{ permitReason }}</h4>
    </div>

    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Attachment</h4>   
    </div>
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="attachment; else noImage">
        <img [src]="attachment" alt="Attachment" (click)="openImageModal(attachment)" style="width: 100%; height: 100%; cursor: pointer;">
      </ng-container>
      <ng-template #noImage>
        <span>No image available</span>
      </ng-template>
    </div>
  </div>

  <div class="col-12">
    <div class="d-flex justify-content-between" *ngIf="status != 'WAITING'">
      <h4 class="font-weight-bold">Keterangan approval</h4>
      <h4>{{ notesApproval }}</h4>
    </div>
    
  <div
    class="d-flex"
    style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
  >
    <button
      nz-button
      nzType="default"
      nzDanger
      style="background-color: rgb(231, 64, 64) !important; color: white;"
      (click)="closeModal()"
    >
      Close
    </button>
  </div>
</div>
