import { Injectable } from '@angular/core'
import store from 'store'
import { ApiService } from '../api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  ListTypeofPermit,
  ListWaitingForPermit,
  QueryTablePermit,
  RequestCoworking,
  RequestSpecialPermit,
} from '../interface/permit-management.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PermitManagementService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }

  getTypeofPermit() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/permit-types',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getLateType() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/late-reason',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getSpecialPermitQuota() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/quota-special',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getPermitList(
    selectedFilter: string,
    queryTable: QueryTablePermit,
    selectedType: string,
  ): Observable<ListWaitingForPermit> {
    let filters = []
    if (selectedType) {
      filters = [
        {
          field: 'status',
          keyword: selectedFilter,
        },

        {
          field: 'type',
          keyword: selectedType,
        },
      ]
    }

    if (!selectedType) {
      filters = [
        {
          field: 'status',
          keyword: selectedFilter,
        },
      ]
    }

    let query = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: queryTable.sort.field || 'date',
        order: queryTable.sort.order || 'descend',
      },
      filters: filters,
    }

    return this.http.post<ListWaitingForPermit>(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/data',
      query,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getDetailPermit(id: string) {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + `/api/permit-management/data/${id}`,

      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  requestPermitAPI(payload: any) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/request',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  cancelPermit(id: string) {
    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + `/api/permit-management/data/${id}`,
      {},
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  downloadSPTFileAPI(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, {
      responseType: 'blob',
    })
  }

  // HEAD APPROVAL
  getListApprovalPermit(queryTable, selectedFilter: string) {
    let filter = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: queryTable.sort.field || 'date',
        order: queryTable.sort.order || 'descend',
      },
      filters: [
        {
          field: 'status',
          keyword: selectedFilter,
        },
      ],
    }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/approval',
      filter,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  giveCancelRequestPermit(id: string, notes: string) {
    let body = { notes_approval: notes }
    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + `/api/permit-management/approval/${id}`,
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  giveApproveRequestPermit(id: string, notes: string) {
    let body = { notes_approval: notes }

    return this.http.patch(
      this.apiService.API_SUNSHINE_SERVER + `/api/permit-management/approval/${id}`,
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  exportApprovalPermit(mode: string, date: string, approval_by: string) {
    let body = { mode, date, approval_by }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/approval/export',
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  // Leave Management/Approval HR
  getListApprovalPermitHR(queryTable, selectedFilter: string) {
    let filter = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: queryTable.sort.field || 'date',
        order: queryTable.sort.order || 'descend',
      },
      filters: [
        {
          field: 'status',
          keyword: selectedFilter,
        },
      ],
    }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/permit-management/approval/hr',
      filter,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }
}
