import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { isBefore, isAfter, startOfDay, addDays, format } from 'date-fns'
import { ApiService } from 'src/app/services/api.service'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import storeeeee from 'store'
import { Observable, Observer } from 'rxjs'
import { EmployeeService } from 'src/app/services/employee.service'
import { PermitManagementService } from 'src/app/services/permit-management/permit-management.service'
import {
  ListDivisionType,
  ListLateType,
  ListTypeofPermit,
  RequestSpecialPermit,
} from 'src/app/services/interface/permit-management.model'
import { timeEnd } from 'node:console'
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators'
import { HrService } from 'src/app/services/hr.service'

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-add-permit-request',
  templateUrl: './add-permit-request.component.html',
  styleUrls: ['./add-permit-request.component.scss'],
})
export class AddPermitRequestComponent implements OnInit {
  formAddPermitRequest: FormGroup
  isLoading: boolean = false
  fileCancelled: boolean = false
  fileSelected: boolean = false
  disableTimeUntil: boolean = true

  permitType: string = ''
  permitShift: string = 'morning'
  fileName: string = ''
  placementCode: string = null
  otherPlace: string = ""
  sickDateFrom: Date | null = null
  sickDateUntil: Date | null = null
  fileList: NzUploadFile[] = []

  previewUrl: string | null = null
  time: Date | null = null

  selectedValue = null
  filename = null
  stage = null
  date_upload = null
  selectedSPTFile = null

  listofDivision: ListDivisionType[] = []
  listPermitType: ListTypeofPermit[] = []
  listLateType: ListLateType[] = []
  listPlacement: any[] = []

  stepMinute = 15
  disabledHourMorning: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23, 8, 9, 10, 11, 12, 13]
  disabledHourEvening: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23, 14, 15, 16, 17, 18]
  
  SPTSelectValue = [
    {
      label: 'ONINDO',
      value: 'ONINDO',
    },
    {
      label: 'ONMART',
      value: 'ONMART',
    },
    {
      label: 'OTB',
      value: 'OTB',
    },
    {
      label: 'OTI',
      value: 'OTI',
    },
    {
      label: 'RBI',
      value: 'RBI',
    },
    {
      label: 'REI',
      value: 'REI',
    },
    {
      label: 'RMI',
      value: 'RMI',
    },
    {
      label: 'ROLI',
      value: 'ROLI',
    },
  ]

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
    private generalService: ApiService,
    private employeeService: EmployeeService,
    private permitManagementService: PermitManagementService,
    private hrService: HrService,
    private modalService: NzModalService,    
  ) {
    this.formAddPermitRequest = this.fb.group({
      employee_name: ['', [Validators.required]],
      division: ['', [Validators.required]],
      permitDate: ['', Validators.required],
      permitTimeFrom: [new Date(new Date().setHours(8, 30, 0))],
      permitTimeUntil: [new Date(new Date().setHours(13, 0, 0))],
      reason: ['', [Validators.required]],
      attachment: null,
    })
  }

  ngOnInit() {
    this.generalService.userInfo().subscribe(result => {
      this.formAddPermitRequest.patchValue({
        employee_name: result.name,
      })
      this.listDivisionPatch(result.division)
    })
    this.listDivision()
    this.listTypeofPermit()
    this.listLateTypetoAPI()
    this.listPlacementFunction()
  }

  listDivisionPatch(division: string): void {
    this.employeeService.getListDivision().subscribe((result: any) => {
      const divisionValue = result.find((value: any) => value.id == division)
      this.formAddPermitRequest.patchValue({
        division: divisionValue.id,
      })
    })
  }
  listDivision(): void {
    this.employeeService.getListDivision().subscribe((result: any[]) => {
      this.listofDivision = result
    })
  }

  listTypeofPermit(): void {
    this.permitManagementService.getTypeofPermit().subscribe((result: any) => {
      this.listPermitType = result
    })
  }

  listLateTypetoAPI(): void {
    this.permitManagementService.getLateType().subscribe((result: any) => {
      this.listLateType = result.reason_list
    })
  }

  listPlacementFunction(): void {
    this.hrService.getEmployeePlacement().subscribe((result: any) => {
      result.push({ code: 'other', name: 'lainnya' })
      this.listPlacement = result
    })
  }

  disabledHours(): number[] {
    return [0, 1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  }

  onChangeRadio(value: any): void {
    if (value == 'morning') {
      this.formAddPermitRequest.patchValue({
        permitTimeFrom: new Date(new Date().setHours(8, 30, 0)),
        permitTimeUntil: new Date(new Date().setHours(13, 0, 0)),
      })
    } else {
      this.formAddPermitRequest.patchValue({
        permitTimeFrom: new Date(new Date().setHours(13, 0, 0)),
        permitTimeUntil: new Date(new Date().setHours(17, 30, 0)),
      })
    }
  }

  onCancel(): void {
    this.modal.destroy()
  }

  onChangeLateTime(result: Date): void {
    this.formAddPermitRequest.patchValue({
      permitDate: Date.now(),
    })
  }

  onChangeEnd(result: Date): void {
    // console.log('onChangeEnd: ', result)
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false
    }

    const fiveDaysFromToday = new Date()
    fiveDaysFromToday.setDate(fiveDaysFromToday.getDate() - 1)

    // return startValue.getDate() > this.formAddAnnualLeave.value.endValue.getDate()
    return startValue < fiveDaysFromToday
  }

  disabledEndDate = (endValue: Date): boolean => {
    const startValue = this.formAddPermitRequest.value.startValue
    if (!endValue || !startValue) {
      return false
    }

    const maxEndDate = addDays(startValue, 5)
    return isBefore(endValue, startOfDay(startValue)) || isAfter(endValue, startOfDay(maxEndDate))
  }

  disabledStartSickDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false
    }

    const fiveDaysFromToday = new Date()
    fiveDaysFromToday.setDate(fiveDaysFromToday.getDate() - 3)

    // return startValue.getDate() > this.formAddAnnualLeave.value.endValue.getDate()
    return startValue < fiveDaysFromToday
  }

  isDisabledDate = (current: Date): boolean => {
    const dayOfWeek = current.getDay(); // 0 = Sunday, 1 = Monday, etc.
    return dayOfWeek === 0 || dayOfWeek === 6; // Disable Saturdays and Sundays
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open()
    }
  }

  handleEndOpenChange(open: boolean): void {
    // console.log({ open })
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('attachment', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.generalService.API_SUNSHINE_SERVER +
        '/api/permit-management/upload-attachment/' +
        this.permitType,
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
          this.filename = event.body.filename
          this.date_upload = event.body.date
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.formAddPermitRequest.patchValue({ attachment: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.formAddPermitRequest.patchValue({ attachment: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }

  downloadSPTFile() {
    var fileUrl = `https://storage.onindonesia.id/sunshine.onindonesia.id/docs/hr/word/spt/${this.selectedSPTFile}.docx`
    this.permitManagementService.downloadSPTFileAPI(fileUrl).subscribe({
      next: result => {
        // Extract filename from URL or provide default
        const filename = fileUrl.split('/').pop() || 'downloaded-file'

        // Create blob URL
        const url = window.URL.createObjectURL(result)

        // Create temporary link element
        const link = document.createElement('a')
        link.href = url
        link.download = filename

        // Trigger download
        document.body.appendChild(link)
        link.click()

        // Cleanup
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        this.modalService.success({
          nzTitle: 'Success!',
          nzContent: 'Silahkan cek progress download file di browser',
        })
      },
      error: error => {
        this.modalService.error({
          nzTitle: 'Error',
          nzContent: 'Terdapat kesalahan pada server, silahkan coba beberapa saat lagi',
        })
      },
    })
  }

  submitForm(): boolean {
    this.isLoading = true
    var dateBody = []
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    switch (this.permitType) {
      case 'sakit':
      case 'keluar_kantor':
        dateBody = [
          format(this.sickDateFrom, 'yyyy-MM-dd'),
          format(this.sickDateUntil, 'yyyy-MM-dd'),
        ]
        break
      case 'telat':
      case 'khusus':
        dateBody = [
          format(this.formAddPermitRequest.value.permitDate, 'yyyy-MM-dd'),
          format(this.formAddPermitRequest.value.permitDate, 'yyyy-MM-dd'),
        ]
        break
    }
    if (this.formAddPermitRequest.valid) {
      let payload = {
        division_id: this.formAddPermitRequest.value.division,
        type: this.permitType,
        date: dateBody,
        reason: this.formAddPermitRequest.value.reason,
      }
      if (this.permitType == 'telat' || this.permitType == 'khusus') {
        payload['time'] = [
          format(this.formAddPermitRequest.get('permitTimeFrom').value, 'HH:mm:ss'),
          format(this.formAddPermitRequest.get('permitTimeUntil').value, 'HH:mm:ss'),
        ]
        payload['filename'] = this.filename
        payload['date_upload'] = this.date_upload
      } else if (this.permitType == 'sakit') {
        payload['filename'] = this.filename
        payload['date_upload'] = this.date_upload
      } else if (this.permitType == 'keluar_kantor') {
        payload['placement_code'] = this.placementCode
        if (this.placementCode == 'other') {
          payload['other_place'] = this.otherPlace
          delete payload['placement_code'];
        }
      }
      // this.modal.destroy()
      // var formSubs: RequestSpecialPermit = payload
      console.log(payload)
      this.permitManagementService.requestPermitAPI(payload).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Request Leave')
          this.modal.destroy()
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
