import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HrService } from 'src/app/services/hr.service'
import * as Reducers from 'src/app/store/reducers'
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  role: string
  code: string
  date: FormControl = new FormControl(new Date())

  isLoading: boolean = false
  dataAttendances = []
  constructor(
    private store: Store<any>,
    private hrService: HrService,
    private notification: NzNotificationService,
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
      this.code = state.code
      this.fetchAttendancePersonal()
    })
  }

  ngOnInit() {}

  formatDate(date: Date): string {
    if (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const date1 = '01'
      return `${year}-${month}-${date1}`
    } else {
      return ''
    }
  }

  formatMonth(apiDateString: string): string {
    const parts = apiDateString.split('-')
    if (parts.length === 3) {
      const year = parts[0]
      const month = this.getMonthName(parseInt(parts[1], 10))
      const day = parts[2]
      return `${year} ${month} ${day}`
    } else {
      // Handle invalid date format
      return apiDateString
    }
  }

  getMonthName(monthNumber: number): string {
    const monthNames = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ]
    return monthNames[monthNumber - 1]
  }

  fetchAttendancePersonal() {
    this.isLoading = true

    const formattedDate = this.formatDate(this.date.value)
    this.hrService.attendancePersonal(this.code, formattedDate).subscribe(
      r => {
        // console.log(r)
        this.isLoading = false
        if (r.length === 0) {
          // this.notification.info('Info', 'Data not found')
          this.dataAttendances = r
        } else {
          this.notification.success('Success', 'Get data successfully')
          this.isLoading = false
          this.dataAttendances = r
        }
      },
      err => {
        if (err.error.message === "'date' must match format dateOnly") {
          this.isLoading = false
        } else {
          // this.notification.error('Error', err.error.message)
          this.isLoading = false
        }
      },
    )
  }
}
