import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { isBefore, isAfter, startOfDay, addDays, format } from 'date-fns'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import {
  ListSubstituteType,
  RequestAnnualLeaveType,
} from 'src/app/services/interface/leave-management/leave.model'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'app-add-annual-leave',
  templateUrl: './add-annual-leave.component.html',
  styleUrls: ['./add-annual-leave.component.scss'],
})
export class AddAnnualLeaveComponent implements OnInit {
  formAddAnnualLeave: FormGroup
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []
  employeeCode: string = ''

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
    private store: Store<any>,
  ) {
    this.formAddAnnualLeave = this.fb.group({
      substitution: ['', [Validators.required]],
      startValue: ['', Validators.required],
      endValue: ['', Validators.required],
      reason: ['', [Validators.required]],
    });
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.employeeCode = state.code
    })
  }

  ngOnInit(): void {
    this.fetchListSubstitute()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    if (this.formAddAnnualLeave.valid) {
      let payload = {
        substitute_code: this.formAddAnnualLeave.value.substitution,
        date: [
          format(this.formAddAnnualLeave.value.startValue, 'yyyy-MM-dd'),
          format(this.formAddAnnualLeave.value.endValue, 'yyyy-MM-dd'),
        ],
        reason: this.formAddAnnualLeave.value.reason,
      }
      this.modal.destroy()
      var formSubs: RequestAnnualLeaveType = payload
      this.leaveManagementService.requestAnnualLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Request Leave')
          this.modal.destroy()
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  onCancel(): void {
    this.modal.destroy()
  }

  onChangeStart(result: Date): void {
    // console.log('onChangeStart: ', result)
  }
  onChangeEnd(result: Date): void {
    // console.log('onChangeEnd: ', result)
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false
    }

    const fiveDaysFromToday = new Date()
    fiveDaysFromToday.setDate(fiveDaysFromToday.getDate() + 5)

    // return startValue.getDate() > this.formAddAnnualLeave.value.endValue.getDate()
    return startValue < fiveDaysFromToday
  }

  disabledEndDate = (endValue: Date): boolean => {
    const startValue = this.formAddAnnualLeave.value.startValue
    if (!endValue || !startValue) {
      return false
    }

    const maxEndDate = addDays(startValue, 5)
    return isBefore(endValue, startOfDay(startValue)) || isAfter(endValue, startOfDay(maxEndDate))
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open()
    }
  }

  handleEndOpenChange(open: boolean): void {
    // console.log({ open })
  }

  fetchListSubstitute() {
    this.isLoading = true
    this.leaveManagementService.getListSubstitute().subscribe((r: any) => {
      r = r.filter((item: any) => item.employee_code !== this.employeeCode);
      this.isLoading = false
      this.listOfSubtitution = r
    })
  }
}
