<div class="row">
  <div class="col-6">
    <div class="d-flex">
      <h4 class="font-weight-bold">Nama</h4>
      <h4 style="padding-left: 75px;">{{ employeeName }}</h4>
    </div>
    <div class="d-flex">
      <h4 class="font-weight-bold">Divisi</h4>
      <h4 style="padding-left: 75px;">{{ division }}</h4>
    </div>
    <div class="d-flex">
      <h4 class="font-weight-bold">Tipe</h4>
      <h4 style="padding-left: 90px;">{{ permitType }}</h4>
    </div>
    <div class="d-flex">
      <h4 class="font-weight-bold">Tanggal Izin</h4>
      <h4 style="padding-left: 30px;">{{ permitDate[0] }}</h4>
      <h4>-</h4>
      <h4>{{ permitDate[1] }}</h4>
    </div>
    <div class="d-flex" *ngIf="permitType != 'Izin sakit' && timeofPermit.length > 0">
      <h4 class="font-weight-bold">Waktu Izin</h4>
      <h4 style="padding-left: 75px;">{{ timeofPermit[0] }}</h4>
      <h4>-</h4>
      <h4>{{ timeofPermit[1] }}</h4>
    </div>
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Placement</h4>
      <h4>{{ placement || otherPlacement }}</h4>
    </div>
  </div>
  
  <div class="col-6">
    <div class="d-flex">
      <h4 class="font-weight-bold">Alasan izin</h4>
      <h4 style="padding-left: 75px;">{{ permitReason }}</h4>
    </div>

    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">Attachment</h4>   
    </div>
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="attachment; else noImage">
        <img [src]="attachment" alt="Attachment" (click)="openImageModal(attachment)" style="width: 100%; height: 100%; cursor: pointer;">
      </ng-container>
      <ng-template #noImage>
        <span>No image available</span>
      </ng-template>
    </div>
  </div>
</div>

<hr *ngIf="filterStatus === 'WAITING'" />
<div class="col-12" *ngIf="filterStatus === 'WAITING'">
  <nz-form-item>
    <nz-form-label nzFor="notes_approval" class="text-left" style="font-weight: 600;"
      >Add Notes</nz-form-label
    >
    <input
      nz-input
      id="notes_approval"
      [(ngModel)]="notesApproval"
      class="col-10 input-notes"
    />
  </nz-form-item>
</div>

<div
  class="d-flex"
  style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
>
  <button
    nz-button
    *ngIf="filterStatus === 'WAITING'"
    nzType="primary"
    (click)="approvePermit(permitID)"
  >
    Approve
  </button>
  <button
    nz-button
    *ngIf="filterStatus === 'WAITING'"
    nzType="default"
    nzDanger
    style="background-color: rgb(231, 64, 64) !important; color: white;"
    (click)="rejectPermit(permitID)"
  >
    Reject
  </button>
  <button nz-button nzType="" (click)="closeModal()">Cancel</button>
</div>
