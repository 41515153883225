import { Component, inject, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { ListSubstituteType } from 'src/app/services/interface/leave-management/leave.model'
import { PermitManagementService } from 'src/app/services/permit-management/permit-management.service'
import { NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'app-view-detail-permit-request',
  templateUrl: './view-detail-permit-request.component.html',
  styleUrls: ['./view-detail-permit-request.component.scss'],
})
export class ViewDetailPermitRequestComponent implements OnInit {
  @Input() permitID: string
  @Input() selectedFilter: string
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []

  employeeName: string = ''
  division: string = ''
  permitType: string = ''
  permitReason: string = ''
  placement: string = ''
  otherPlacement: string = ''
  attachment: string = ''
  notesApproval: string = ''
  status: string = ''

  timeofPermit: any
  permitDate: any

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private permitManagementService: PermitManagementService,
    private nzImageService: NzImageService
  ) {}

  ngOnInit(): void {
    this.permitManagementService.getDetailPermit(this.permitID).subscribe(
      (r: any) => {
        this.employeeName = r.employee_name
        this.division = r.division
        this.permitType = r.type
        this.permitReason = r.reason
        this.attachment = r.attachment
        this.permitDate = r.date
        if (Array.isArray(this.permitDate)) {
          this.permitDate = this.permitDate[0] + ' - ' + this.permitDate[1]
        }
        this.timeofPermit = r.time
        if (Array.isArray(this.timeofPermit)) {
          this.timeofPermit = this.timeofPermit[0] + ' - ' + this.timeofPermit[1]
        }
        if (this.status != 'WAITING') {
          this.notesApproval = r.notes_approval
        }
        this.placement = r.placement
        this.otherPlacement = r.other_place
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  openImageModal(attachment: any): void {
    this.nzImageService.preview(
      [{
        src: attachment,
        alt: 'attachment-preview'
      }],
      {
        nzZoom: 1,
        nzRotate: 0 
      }
    );
  }

  closeModal(): void {
    this.modal.destroy()
  }
}
