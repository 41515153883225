import { Injectable } from '@angular/core'
import store from 'store'
import { ApiService } from './api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }
  
  getListDivision() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/division-list',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

}
