import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTableLeave } from 'src/app/services/interface/general.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AddPermitRequestComponent } from './add-permit-request/add-permit-request.component'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'
import { PermitManagementService } from 'src/app/services/permit-management/permit-management.service'

@Component({
  selector: 'app-permit-request',
  templateUrl: './permit-request.component.html',
  styleUrls: ['./permit-request.component.scss'],
})
export class PermitRequestComponent implements OnInit {
  listOfData: AnnualLeaveType[] = []
  annualSpecialPermit = null
  isAnnualLeave = false

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  selectedFilter = 'WAITING'
  selectedType = null
  selectedStatus = null
  selectedSPTFile = null

  // FOR DEVELOPMENT PURPOSE
  listPermitType: any[] = [
    {
      label: 'Leave Permit',
      value: '1',
    },
    {
      label: 'Sick Permit',
      value: '2',
    },
    {
      label: 'Late Permit',
      value: '3',
    },
    {
      label: 'Special Permit',
      value: '4',
    },
  ]

  filterStatus = [
    {
      label: 'Menunggu',
      value: 'WAITING',
    },
    {
      label: 'Disetujui',
      value: 'APPROVED',
    },
    {
      label: 'Tidak Disetujui',
      value: 'REJECTED',
    },
  ]

  SPTSelectValue = [
    {
      label: 'ONINDO',
      value: 'ONINDO',
    },
    {
      label: 'ONMART',
      value: 'ONMART',
    },
    {
      label: 'OTB',
      value: 'OTB',
    },
    {
      label: 'OTI',
      value: 'OTI',
    },
    {
      label: 'RBI',
      value: 'RBI',
    },
    {
      label: 'REI',
      value: 'REI',
    },
    {
      label: 'RMI',
      value: 'RMI',
    },
    {
      label: 'ROLI',
      value: 'ROLI',
    },
  ]

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private permitManagementService: PermitManagementService,
    private modalService: NzModalService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTableLeave = {
    sort: { field: null, order: null },
    annual_leave: false,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  ngOnInit(): void {
    this.checkQuotaSpecialPermit()
    // this.fetchLeaveType()
  }

  fetchPermitList() {
    this.isLoading = true
    this.permitManagementService
      .getPermitList(this.selectedFilter, this.queryTable, this.selectedType)
      .subscribe((r: any) => {
        this.isLoading = false
        this.listOfData = r.rows
        this.totalData = r.count
      }),
      err => {
        this.fetchPermitList()
      }
  }

  onQueryParamsChange(params): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = { field: null, order: null }
    }
    this.fetchPermitList()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = { field: null, order: null }
    this.selectedType = null
    this.fetchPermitList()
  }

  filterType(value: string) {
    // console.log(value)
  }

  addLeave() {
    const modal = this.modal.create({
      nzTitle: 'Form izin',
      nzContent: AddPermitRequestComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Submit',
      nzOkDisabled: true,

      nzOnOk: () => {
        const instance = modal.getContentComponent()
        instance.submitForm()
      },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()

    modal.afterClose.subscribe(() => {
      this.fetchPermitList()
    })
  }

  checkQuotaSpecialPermit() {
    this.isLoading = true
    this.permitManagementService.getSpecialPermitQuota().subscribe((r: any) => {
      this.isLoading = false
      this.annualSpecialPermit = r.quota
    })
  }

  downloadSPTFile() {
    var fileUrl = `https://storage.onindonesia.id/sunshine.onindonesia.id/docs/hr/word/spt/${this.selectedSPTFile}.docx`
    this.permitManagementService.downloadSPTFileAPI(fileUrl).subscribe({
      next: result => {
        // Extract filename from URL or provide default
        const filename = fileUrl.split('/').pop() || 'downloaded-file'

        // Create blob URL
        const url = window.URL.createObjectURL(result)

        // Create temporary link element
        const link = document.createElement('a')
        link.href = url
        link.download = filename

        // Trigger download
        document.body.appendChild(link)
        link.click()

        // Cleanup
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        this.modalService.success({
          nzTitle: 'Success!',
          nzContent: 'Silahkan cek progress download file di browser',
        })
      },
      error: error => {
        this.modalService.error({
          nzTitle: 'Error',
          nzContent: 'Terdapat kesalahan pada server, silahkan coba beberapa saat lagi',
        })
      },
    })
  }
}
