<div class="container-top">
  <div class="col">
    <form
      nz-form
      [nzLayout]="'inline'"
      [formGroup]="form"
      (ngSubmit)="search()"
      class="float-right"
    >
      <!-- <nz-avatar
        nzIcon="download"
        [nzShape]="'square'"
        [nzSize]="'small'"
        style="cursor: pointer; margin-right: 16px; margin-top: 3px;"
        (click)="handleDownload()"
      ></nz-avatar> -->

      <div class="d-flex" style="align-items: center;">
        <div>
          <nz-form-item>
            <nz-select
              nzPlaceHolder="MODE"
              [(ngModel)]="modeDownload"
              (ngModelChange)="onChangeMode($event)"
              formControlName="mode"
            >
              <nz-option nzValue="yearly" nzLabel="Yearly"></nz-option>
              <nz-option nzValue="monthly" nzLabel="Monthly"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
        <div>
          <nz-form-item>
            <nz-date-picker
              [(ngModel)]="dateDownload"
              (ngModelChange)="onChange($event)"
              nzPlaceHolder="DATE"
              formControlName="date"
              style="max-width:  fit-content"
            ></nz-date-picker>
          </nz-form-item>
        </div>

        <div>
          <nz-form-item>
            <nz-select
              nzPlaceHolder="Approval by"
              [(ngModel)]="approvalDownload"
              (ngModelChange)="onChangeApproval($event)"
              formControlName="approvalByDownload"
            >
              <nz-option nzValue="HEAD" nzLabel="Head"></nz-option>
              <nz-option nzValue="CEO" nzLabel="CEO"></nz-option>
              <nz-option nzValue="HR" nzLabel="HR"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
      </div>

      <div style="cursor: pointer; margin-right: 16px; margin-top: 3px;" (click)="handleDownload()">
        <span nz-icon nzType="download" nzTheme="outline"></span> Download
      </div>

      <!-- <nz-form-item>
        <nz-form-control>
          <nz-select
            id="prefix"
            [formControl]="form.controls['prefix']"
            nzPlaceHolder="Select Prefix ..."
            style="width: 150px;"
            (ngModelChange)="changePrefix($event)"
          >
            <nz-option nzValue="name" nzLabel="Name"></nz-option>
            <nz-option nzValue="capacity" nzLabel="Capacity"></nz-option>
            <nz-option nzValue="status" nzLabel="Status"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <div
          *ngIf="
            form.controls['prefix'].value == 'status' || form.controls['prefix'].value == 'function'
          "
        >
          <nz-form-control>
            <nz-select nzPlaceHolder="Keyword ..." formControlName="keyword">
              <ng-container *ngIf="form.controls['prefix'].value == 'status'">
                <nz-option [nzValue]="true" nzLabel="ENABLED"></nz-option>
                <nz-option nzValue="false" nzLabel="DISABLED"></nz-option>
              </ng-container>
            </nz-select>
          </nz-form-control>
        </div>
        <div
          *ngIf="
            form.controls['prefix'].value != 'status' && form.controls['prefix'].value != 'function'
          "
        >
          <nz-form-control>
            <input
              [formControl]="form.controls['keyword']"
              nz-input
              placeholder="Keyword ..."
              style="width: 100px;"
            />
          </nz-form-control>
        </div>
      </nz-form-item>
      <nz-form-item> </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
            Search
          </button>
        </nz-form-control>
      </nz-form-item> -->
    </form>
  </div>
</div>

<div>
  <nz-tabset>
    <nz-tab nzTitle="Waiting"
      ><app-tab-waiting-leave-approval></app-tab-waiting-leave-approval
    ></nz-tab>
    <nz-tab nzTitle="Approved">
      <app-tab-approved-leave-approval></app-tab-approved-leave-approval>
    </nz-tab>
    <nz-tab nzTitle="Canceled"
      ><app-tab-cancelled-leave-approval></app-tab-cancelled-leave-approval
    ></nz-tab>
  </nz-tabset>
</div>
