<div class="container-top">
  <div class="col">
    <form
      nz-form
      [nzLayout]="'inline'"
      [formGroup]="form"
      (ngSubmit)="search()"
      class="float-right"
    >
      <!-- <nz-avatar
        nzIcon="download"
        [nzShape]="'square'"
        [nzSize]="'small'"
        style="cursor: pointer; margin-right: 16px; margin-top: 3px;"
        (click)="handleDownload()"
      ></nz-avatar> -->

      <div class="d-flex" style="align-items: center;">
        <div>
          <nz-form-item>
            <nz-select
              nzPlaceHolder="Period"
              [(ngModel)]="modeDownload"
              (ngModelChange)="onChangeMode($event)"
              formControlName="mode"
            >
              <nz-option nzValue="yearly" nzLabel="Yearly"></nz-option>
              <nz-option nzValue="monthly" nzLabel="Monthly"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
        <div>
          <nz-form-item>
            <nz-date-picker
              [(ngModel)]="dateDownload"
              (ngModelChange)="onChange($event)"
              nzPlaceHolder="DATE"
              formControlName="date"
              style="max-width:  fit-content"
            ></nz-date-picker>
          </nz-form-item>
        </div>
      </div>

      <button nz-button [nzSize]="'large'" nzType="default" (click)="handleDownload()" [disabled]="!dateDownload">
        <span nz-icon nzType="download" nzTheme="outline"></span> Download data
      </button>
    </form>
  </div>
</div>

<div>
  <nz-tabset>
    <nz-tab nzTitle="Waiting">
      <ng-template nz-tab nzDestroyInactiveTabPane>
        <app-permit-waiting-head-approval></app-permit-waiting-head-approval>
      </ng-template>
    </nz-tab>
    <nz-tab nzTitle="Approved">
      <ng-template nz-tab nzDestroyInactiveTabPane>
        <app-permit-approved-head-approval></app-permit-approved-head-approval>
      </ng-template>
    </nz-tab>
    <nz-tab nzTitle="Canceled">
      <ng-template nz-tab nzDestroyInactiveTabPane>
        <app-permit-cancelled-head-approval></app-permit-cancelled-head-approval>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>
