import { Component, Input, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTableLeave } from 'src/app/services/interface/general.model'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'
import { ViewDetailPermitRequestComponent } from '../view-detail-permit-request/view-detail-permit-request.component'
import { PermitManagementService } from 'src/app/services/permit-management/permit-management.service'

@Component({
  selector: 'app-tab-approved-permit-request',
  templateUrl: './tab-approved-permit-request.component.html',
  //   styleUrls: ['./annual-leave.component.scss'],
})
export class TabApprovedPermitRequestComponent implements OnInit {
  @Input() selectedType: string | null = null

  selectedFilter = 'APPROVED'
  // selectedType = null
  listOfData: AnnualLeaveType[] = []
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  form: FormGroup
  isAnnualLeave = false

  constructor(
    private fb: FormBuilder,
    private permitManagementService: PermitManagementService,
    private modal: NzModalService,
    private notification: NzNotificationService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTableLeave = {
    sort: { field: null, order: null },
    annual_leave: false,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns = [
    {
      name: 'ID',
      key: 'id',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '30px',
    },
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '40px',
    },
    {
      name: 'Tipe Izin',
      key: 'type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Jumlah izin',
      key: 'total_permit',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Tanggal izin',
      key: 'date',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '30px',
    },
    {
      name: 'Catatan tidak diterima',
      key: 'notes_approval',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedType']) {
    }
  }

  fetchAnnualLeaveList() {
    this.isLoading = true
    this.permitManagementService
      .getPermitList(this.selectedFilter, this.queryTable, this.selectedType)
      .subscribe(
        (r: any) => {
          this.isLoading = false
          // this.listOfData = r.rows

          this.listOfData = r.rows.map((item: any) => ({
            ...item,
            status: this.transformStatus(item.status),
          }))

          this.totalData = r.count
        },
        (error: any) => {
          this.isLoading = false
        },
      ),
      err => {
        this.fetchAnnualLeaveList()
      }
  }

  transformStatus(status: string): string {
    switch (status) {
      case 'WAITING':
        return 'Waiting for Approval HR'
      case 'CANCELED':
        return 'Canceled'
      case 'APPROVED':
        return 'Approved'
      default:
        return status
    }
  }

  deleteLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Apakah anda ingin membatalkan izin?',
      nzContent: '<b style="color: red;">Izin yang dibatalkan, tidak bisa di ubah kembali</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.permitManagementService.cancelPermit(id).subscribe(
          r => {
            this.notification.success('Success', 'Successfully cancel leave!')
            this.fetchAnnualLeaveList()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchAnnualLeaveList()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  viewDetailLeave(permitID: string) {
    const modal = this.modal.create({
      nzTitle: 'View Detail Permit Request',
      nzContent: ViewDetailPermitRequestComponent,
      nzStyle: { top: '50px' },
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        permitID: permitID,
        selectedFilter: this.selectedFilter,
      },

      nzMaskClosable: false,
      // nzFooter: [
      //   {
      //     label: 'Close',
      //     danger: true,
      //     onClick() {
      //       modal.destroy()
      //     },
      //   },
      // ],
      nzWidth: '50%',
    })
    modal.afterClose.subscribe(() => this.fetchAnnualLeaveList())
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = { field: null, order: null }
    }

    this.fetchAnnualLeaveList()
  }
}
