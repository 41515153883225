import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { format } from 'date-fns'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import {
  ListSubstituteType,
  RequestSpecialLeaveType,
} from 'src/app/services/interface/leave-management/leave.model'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'app-add-special-leave',
  templateUrl: './add-special-leave.component.html',
  styleUrls: ['./add-special-leave.component.scss'],
})
export class AddSpecialLeaveComponent implements OnInit {
  formAddSpecialLeave: FormGroup
  isLoading: boolean = false
  selectedValue = null
  selectedType = null
  listOfSubtitution: ListSubstituteType[] = []
  listLeaveType: [] = []

  fileList: NzUploadFile[] = []
  previewImage: string | undefined = ''
  previewVisible = false
  uploadDate = null
  employeeCode: string = ''
  filename = null
  stage = null
  date_upload = null

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
    private store: Store<any>,
  ) {
    this.formAddSpecialLeave = this.fb.group({
      type: ['', [Validators.required]],
      substitution: ['', [Validators.required]],
      startValue: ['', Validators.required],
      endValue: ['', Validators.required],
      reason: ['', [Validators.required]],
      attachment: [null],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.employeeCode = state.code
    })
  }

  ngOnInit(): void {
    this.fetchListSubstitute()
    this.fetchLeaveType()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    if (this.formAddSpecialLeave.valid) {
      let date = [
        format(this.formAddSpecialLeave.value.startValue, 'yyyy-MM-dd'),
        format(this.formAddSpecialLeave.value.endValue, 'yyyy-MM-dd'),
      ]

      let payload = {
        type: this.selectedType,
        substitute_code: this.formAddSpecialLeave.value.substitution,
        date: date,
        reason: this.formAddSpecialLeave.value.reason,
        filename: this.filename,
        stage: this.stage,
        date_upload: this.date_upload,
      }

      // this.modal.destroy()
      var formSubs: RequestSpecialLeaveType = payload
      this.leaveManagementService.requestSpecialLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Request Leave')
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          this.modal.destroy()

          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          this.modal.destroy()
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      // this.modal.destroy()
      return false
    }
  }

  onCancel(): void {
    this.modal.destroy()
  }

  onChangeStart(result: Date): void {
    // console.log('onChangeStart: ', result)
  }
  onChangeEnd(result: Date): void {
    // console.log('onChangeEnd: ', result)
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open()
    }
  }

  handleEndOpenChange(open: boolean): void {
    // console.log({ open })
  }

  fetchListSubstitute() {
    this.isLoading = true
    this.leaveManagementService.getListSubstitute().subscribe((r: any) => {
      r = r.filter((item: any) => item.employee_code !== this.employeeCode);
      this.isLoading = false
      this.listOfSubtitution = r
    })
  }

  fetchLeaveType() {
    this.leaveManagementService.listLeaveType().subscribe((r: any) => {
      console.log(r.types)
      r.types.forEach((element: string, index: number) => {
        switch (element) {
          case 'Marriage Leave':
            r.types[index] = 'Marriage Leave (Cuti nikah)'
            break
          case 'Maternity Leave':
            r.types[index] = 'Maternity Leave (Cuti bersalin atau lahiran)'
            break
          case 'Paternity Leave':
            r.types[index] = 'Paternity Leave (Cuti suami untuk istri bersalin)'
            break
          case 'Religious Leave':
            r.types[index] = 'Religious Leave (Cuti keagamaan)'
            break
          case 'Bereavement Leave':
            r.types[index] = 'Bereavement Leave (Cuti kematian)'
            break
        }
      })
      const leaveTypes = r.types.map((leaveType: string, index: number) => ({
        label: leaveType,
        value: index.toString(), // You can use any unique identifier for the value
      }))
      this.listLeaveType = leaveTypes
    })
  }

  customUpload = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('attachment', item.file as any)

    if (this.selectedType) {
      this.leaveManagementService.uploadAttachment(formData, this.selectedType).subscribe(
        (r: any) => {
          item.onSuccess(r, item.file, r)

          this.filename = r.filename
          this.stage = r.stage
          this.date_upload = r.date
        },
        error => {
          item.onError(error, item.file)
        },
      )
    }
  }

  onTypeChange() {
    this.fileList = []
    this.formAddSpecialLeave.get('attachment').setValue(null)
  }

  handleChange(event: { file: NzUploadFile }): void {
    // console.log('Uploaded File:', event.file)
  }
}
